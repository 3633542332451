<template>
    <v-card flat>
        <v-card-title>
            <div style="width:100%" class="d-flex align-center justify-space-between primary--text">
                <div class="d-flex flex-column">
                    <div class="d-flex align-center">
                        <span class="font-weight-bold">SSH keys</span>
                        <v-btn @click="$store.dispatch('userStore/fetchSSHKeys')" color="primary" :loading="fetchingSSHKeys" icon text
                            ><v-icon>refresh</v-icon></v-btn
                        >
                    </div>
                    <span v-if="fetchingSSHKeys === false" class="ma-0 caption">{{ sshKeys.length }} keys found</span>
                    <span v-else class="ma-0 caption">Fetching tokens</span>
                </div>
                <div>
                    <v-btn :disabled="fetchingSSHKeys" :loading="generatingKey" text class="font-weight-bold" color="primary" @click="generateSSHKey()"
                        >Generate SSH key</v-btn
                    >
                </div>
            </div>
        </v-card-title>
        <v-card-text>
            <v-data-iterator v-if="fetchingSSHKeys === false && sshKeys.length > 0" :items="sshKeys">
                <template v-slot:default="props">
                    <div v-for="(item, index) in props.items" :key="item.tknid">
                        <v-divider inset v-if="index > 0" style="opacity: 0.25; border-width: thin" class="mb-2" />
                        <v-list-item two-line>
                            <v-list-item-avatar>
                                <v-icon large>mdi-key</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <span class="subtitle-2 primary--text">{{ item.ssh_key }}</span>
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <div class="d-flex align-center">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on">
                                                <CopyToClipboard
                                                    :textToCopy="item.ssh_key"
                                                    buttonClass="caption font-weight-bold"
                                                    buttonColor="secondary"
                                                    :isTextButton="true"
                                                    buttonName="copy to clipboard"
                                                    :iconButton="true"
                                                    :isSmall="false"
                                                ></CopyToClipboard>
                                            </div>
                                        </template>
                                        <span>Copy to clipboard</span>
                                    </v-tooltip>
                                    <SSHDeleteDialog
                                        :token="item.ssh_key"
                                        dialogTitle="SSH Key Delete"
                                        :apiURL="`/tokens/ssh_keys/${item.tknid}`"
                                        :fetchTokensDispatch="
                                            () => {
                                                $store.dispatch('userStore/fetchSSHKeys')
                                            }
                                        "
                                    ></SSHDeleteDialog>
                                </div>
                            </v-list-item-action>
                        </v-list-item>
                    </div>
                </template>

                <template v-slot:no-results>
                    <v-alert :value="true" color="error" icon="warning" text class="mt-3">Your search for "{{ searchSpace }}" found no results.</v-alert>
                </template>
            </v-data-iterator>
            <div v-else-if="fetchingSSHKeys === true">
                <div
                    v-for="item in [
                        { id: 'c1', opacity: 1 },
                        { id: 'c2', opacity: 0.75 },
                        { id: 'c3', opacity: 0.5 }
                    ]"
                    :key="item.id"
                    class="my-6"
                >
                    <div :style="{ opacity: item.opacity }">
                        <v-skeleton-loader type="list-item-avatar-two-line" class="mx-auto" />
                    </div>
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'
const SSHDeleteDialog = () => import('@/components/DeleteTokenDialog.vue')
const CopyToClipboard = () => import('@/components/CopyToClipboard')

export default {
    data() {
        return {
            dialog: false,
            token: '',
            generatingKey: false
        }
    },
    components: { SSHDeleteDialog, CopyToClipboard },
    methods: {
        generateSSHKey: function() {
            this.$data.generatingKey = true
            this.$axios
                .post('/tokens/ssh_keys')
                .then(response => {
                    this.$store.dispatch('userStore/fetchSSHKeys')
                })
                .finally(() => {
                    this.$data.generatingKey = false
                })
        },
        deleteToken: function(tknid) {
            this.$axios
                .delete(`/tokens/ssh_keys/${tknid}`)
                .then(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Token deleted successfully!',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                    this.$store.dispatch('userStore/fetchSSHKeys')
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Could not delete token.',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                })
        },
        openDialog: function(token) {
            this.$data.token = token
            this.$data.dialog = true
        }
    },
    computed: {
        ...mapState('userStore', ['sshKeys', 'fetchingSSHKeys'])
    }
}
</script>
