var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-title", [
        _c(
          "div",
          {
            staticClass:
              "d-flex align-center justify-space-between primary--text",
            staticStyle: { width: "100%" }
          },
          [
            _c("div", { staticClass: "d-flex flex-column" }, [
              _c(
                "div",
                { staticClass: "d-flex align-center" },
                [
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v("SSH keys")
                  ]),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        loading: _vm.fetchingSSHKeys,
                        icon: "",
                        text: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.$store.dispatch("userStore/fetchSSHKeys")
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("refresh")])],
                    1
                  )
                ],
                1
              ),
              _vm.fetchingSSHKeys === false
                ? _c("span", { staticClass: "ma-0 caption" }, [
                    _vm._v(_vm._s(_vm.sshKeys.length) + " keys found")
                  ])
                : _c("span", { staticClass: "ma-0 caption" }, [
                    _vm._v("Fetching tokens")
                  ])
            ]),
            _c(
              "div",
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "font-weight-bold",
                    attrs: {
                      disabled: _vm.fetchingSSHKeys,
                      loading: _vm.generatingKey,
                      text: "",
                      color: "primary"
                    },
                    on: {
                      click: function($event) {
                        return _vm.generateSSHKey()
                      }
                    }
                  },
                  [_vm._v("Generate SSH key")]
                )
              ],
              1
            )
          ]
        )
      ]),
      _c(
        "v-card-text",
        [
          _vm.fetchingSSHKeys === false && _vm.sshKeys.length > 0
            ? _c("v-data-iterator", {
                attrs: { items: _vm.sshKeys },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(props) {
                        return _vm._l(props.items, function(item, index) {
                          return _c(
                            "div",
                            { key: item.tknid },
                            [
                              index > 0
                                ? _c("v-divider", {
                                    staticClass: "mb-2",
                                    staticStyle: {
                                      opacity: "0.25",
                                      "border-width": "thin"
                                    },
                                    attrs: { inset: "" }
                                  })
                                : _vm._e(),
                              _c(
                                "v-list-item",
                                { attrs: { "two-line": "" } },
                                [
                                  _c(
                                    "v-list-item-avatar",
                                    [
                                      _c("v-icon", { attrs: { large: "" } }, [
                                        _vm._v("mdi-key")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "subtitle-2 primary--text"
                                          },
                                          [_vm._v(_vm._s(item.ssh_key))]
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("v-list-item-action", [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex align-center" },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "div",
                                                        _vm._g({}, on),
                                                        [
                                                          _c(
                                                            "CopyToClipboard",
                                                            {
                                                              attrs: {
                                                                textToCopy:
                                                                  item.ssh_key,
                                                                buttonClass:
                                                                  "caption font-weight-bold",
                                                                buttonColor:
                                                                  "secondary",
                                                                isTextButton: true,
                                                                buttonName:
                                                                  "copy to clipboard",
                                                                iconButton: true,
                                                                isSmall: false
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Copy to clipboard")
                                            ])
                                          ]
                                        ),
                                        _c("SSHDeleteDialog", {
                                          attrs: {
                                            token: item.ssh_key,
                                            dialogTitle: "SSH Key Delete",
                                            apiURL:
                                              "/tokens/ssh_keys/" + item.tknid,
                                            fetchTokensDispatch: function() {
                                              _vm.$store.dispatch(
                                                "userStore/fetchSSHKeys"
                                              )
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        })
                      }
                    },
                    {
                      key: "no-results",
                      fn: function() {
                        return [
                          _c(
                            "v-alert",
                            {
                              staticClass: "mt-3",
                              attrs: {
                                value: true,
                                color: "error",
                                icon: "warning",
                                text: ""
                              }
                            },
                            [
                              _vm._v(
                                'Your search for "' +
                                  _vm._s(_vm.searchSpace) +
                                  '" found no results.'
                              )
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  4156890151
                )
              })
            : _vm.fetchingSSHKeys === true
            ? _c(
                "div",
                _vm._l(
                  [
                    { id: "c1", opacity: 1 },
                    { id: "c2", opacity: 0.75 },
                    { id: "c3", opacity: 0.5 }
                  ],
                  function(item) {
                    return _c("div", { key: item.id, staticClass: "my-6" }, [
                      _c(
                        "div",
                        { style: { opacity: item.opacity } },
                        [
                          _c("v-skeleton-loader", {
                            staticClass: "mx-auto",
                            attrs: { type: "list-item-avatar-two-line" }
                          })
                        ],
                        1
                      )
                    ])
                  }
                ),
                0
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }